function isWebview() {
  return (
    typeof new URL(document.location.href).searchParams.get('webview') ===
    'string'
  )
}

function hideFooterWebview() {
  const footer = document.querySelector('.footer')
  const logo = document.querySelector('.logo')

  if (isWebview()) {
    footer.style.display = 'none'
    logo.style.pointerEvents = 'none'
  }
}

hideFooterWebview()
