import axios from 'axios'
import { closePopup, openPopup } from './popups-dark'
import { formPopup } from './popups-dark'

const $form = document.querySelector('.form')
const check = document.getElementById('check')
const inputs = document.querySelectorAll('.input')
const successPopup = document.querySelector('.success')
const $inputNumber = document.querySelector('.input-number')
const $inputMail = document.querySelector('.input-mail')
const inputWrap = document.querySelector('.input__wrap')
const formBtn = document.querySelector('.form__btn')
const $selectToggle = document.querySelector('.select__toggle')

let isCheck
let isSuccess
let isCheckNumber

const checkInput = () => {
  const inputNumberValue = $inputNumber.value.trim()
  const inputMailValue = $inputMail.value.trim()
  const inputTeamValue = $selectToggle.value.trim()

  function isEmail(email) {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    )
  }

  if (inputTeamValue === '') {
    document
      .querySelector('.error-message')
      .classList.add('error-message--active')
    isCheck = false
  } else {
    document
      .querySelector('.error-message')
      .classList.remove('error-message--active')
  }

  if (inputTeamValue === '') {
    isCheck = false
  } else if (inputMailValue === '') {
    isCheck = false
  } else isCheck = inputNumberValue !== ''

  if (String($inputNumber.value).length < 8) {
    isCheckNumber = false
  }

  if (String($inputNumber.value).length >= 8) {
    isCheckNumber = true
  }

  if (isCheckNumber && isCheck) {
    isSuccess = true
  }

  return isSuccess
}

$inputNumber.addEventListener('input', (evt) => {
  evt.target.value = evt.target.value.replace(/\D/g, '')

  if (String(evt.target.value).length > 8) {
    evt.target.value = String(evt.target.value).slice(0, 8)
  }

  if (String(evt.target.value).length !== 8) {
    inputWrap.classList.add('input__wrap--error')
  } else {
    inputWrap.classList.remove('input__wrap--error')
  }
})

$inputMail.addEventListener('input', (evt) => {
  document
    .querySelector('.input__wrap--id')
    .classList.remove('input__wrap--error')
})

$form?.addEventListener('submit', (evt) => {
  evt.preventDefault()
  checkInput()

  if (isSuccess) {
    const data = new FormData()

    const CONFIG = {
      method: 'post',
      url: 'https://paricup.sportbonus.team/api/two-tickets-per-bets-hockey/create',
      data: data,
    }

    data.append('club', $selectToggle.value)
    data.append('account_number', $inputNumber.value)
    data.append('email', $inputMail.value)
    data.append('agreement', '1')

    axios(CONFIG)
      .then((response) => {
        if (response.status === 200 || response.status === 224) {
          setTimeout(() => {
            closePopup()
            openPopup(successPopup)
          }, 100)
        }
      })
      .catch(function (error) {
        if (error.response.status === 422) {
          console.log(error)
        }
      })
  }
})

$form?.addEventListener('input', (evt) => {
  check.checked === true
    ? formBtn.removeAttribute('disabled', 'disabled')
    : formBtn.setAttribute('disabled', 'disabled')
})
