import { lockScroll, unlockScroll } from './scroll-lock'

export const body = document.querySelector('body')
const popups = document.querySelectorAll('.popup')

const linkPopup = body.querySelectorAll('[data-popup]')

const isEscapeClosePopup = (event) => {
  if (event.key == 'Escape') {
    popups.forEach((popup) => {
      popup.classList.remove('popup-open')
    })
    body.classList.remove('no-scroll')
    unlockScroll()

    document.removeEventListener('keydown', isEscapeClosePopup)
  }
}

popups.forEach((popup) => {
  let buttonClosePopup = popup.querySelector('.popup__close-button')
  buttonClosePopup.addEventListener('click', () => {
    body.classList.remove('no-scroll')
    unlockScroll()

    popup.classList.remove('popup-open')
    document.removeEventListener('keydown', isEscapeClosePopup)
  })
  popup.addEventListener('click', (event) => {
    const target = event.target.closest('.popup__content')
    if (!target) {
      popup.classList.remove('popup-open')
      body.classList.remove('no-scroll')
      unlockScroll()

      document.removeEventListener('keydown', isEscapeClosePopup)
    }
  })
})

linkPopup.forEach((link) => {
  link.addEventListener('click', (event) => {
    event.preventDefault()
    const popup = body.querySelector(`${link.dataset.popup}`)
    popup.classList.add('popup-open')
    body.classList.add('no-scroll')
    lockScroll()
    document.addEventListener('keydown', isEscapeClosePopup)
  })
})
